.icon {
	display: inline-block;
	vertical-align: middle;

	svg {
		display: block;
	}

	&[class*="fill-blue-900"] {

		svg {

			* {

				@apply fill-blue-900;
			}
		}
	}

	&[class*="hover:fill-white"] {

		svg {

			* {

				@apply transition duration-500;
			}
		}

		a:hover & {

			svg {

				* {

					@apply fill-white;
				}
			}
		}
	}
}
