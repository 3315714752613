.js-reveal {

	&.reveal-patch {

		@apply opacity-0;
		@apply scale-110;
		transition-property: opacity, transform;
		transition-timing-function: ease-in-out, theme("transitionTimingFunction.in-circ");
		transition-duration: 0.3s, 0.3s;

		&.is-visible {

			@apply opacity-100;
			@apply scale-100;
		}
	}

	&.reveal-from-y {

		@apply opacity-0;
		@apply translate-y-6;
		transition-property: opacity, transform;
		transition-timing-function: ease-in-out, theme("transitionTimingFunction.out-expo");
		transition-duration: 0.6s, 1.4s;

		&.is-visible {

			@apply opacity-100;
			@apply transform-none;
		}
	}

	&.-reveal-from-y {

		@apply opacity-0;
		@apply -translate-y-6;
		transition-property: opacity, transform;
		transition-timing-function: ease-in-out, theme("transitionTimingFunction.out-expo");
		transition-duration: 0.6s, 1.4s;

		&.is-visible {

			@apply opacity-100;
			@apply transform-none;
		}
	}

	&.reveal-opacity {

		@apply opacity-0;
		@apply transition duration-300;

		&.is-visible {

			@apply opacity-100;
		}
	}

	&.reveal-inner {


		>* {

			@apply opacity-0;
			@apply transform;
			@apply translate-y-6;
			transition-property: opacity, transform;
			transition-timing-function: ease-in-out, theme("transitionTimingFunction.out-expo");
			transition-duration: 0.7s, 1.5s;
		}

		&.is-visible {

			>* {

				@apply opacity-100;
				@apply translate-y-0;

				@for $i from 0 through 15 {
					&:nth-child(#{$i + 1}):not([class*="delay-"]) {
						transition-delay: #{$i * 0.1s};
					}
				}
			}
		}
	}
}
