/* stylelint-disable selector-max-compound-selectors */

/*==========================================================================*\
  Global
\*==========================================================================*/

/* Base
\*==========================================================================*/

*,
*::before,
*::after {
	position: relative;
}

html {
	overflow-x: hidden;
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
	scroll-behavior: smooth;
}

body {
	overflow-x: hidden;
}

h1,
h2,
h3 {

}

p {

	@apply mb-6;
}

// Inline tag
strong,
b {
	font-weight: 700;
}

em {
	font-style: italic;
}

a {
	font-weight: 400;
	text-decoration: none;
	cursor: pointer;
}

// Image and figure
img {
	height: auto;
}

/* Selection
\*==========================================================================*/

::-moz-selection {
	text-shadow: none;

	@apply text-white;
	@apply bg-black;
}

::selection {
	text-shadow: none;

	@apply text-white;
	@apply bg-black;
}
